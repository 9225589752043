<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar
    </v-card-title>

    <v-card-text>
      <v-autocomplete
        v-model="idFromDocumentsSelected"
        :items="documentList"
        item-text="description"
        item-value="id"
        label="Documentos"
        clearable
        multiple
        outlined
        chips
        dense
      />

      <v-autocomplete
        v-model="occupationIdSelected"
        :items="occupationList"
        item-text="name"
        item-value="occupation.id"
        label="Função"
        outlined
        chips
        dense
      />

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-4"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'

export default {
  mixins: [formatters],

  props: {
    occupationList: {
      type: [Array, Object],
      required: true,
    },

    documentList: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      description: '',
      occupationIdSelected: '',

      isLoading: false,

      idFromDocumentsSelected: [],
    }
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const { idFromDocumentsSelected, occupationIdSelected } = this

      const body = {
        documents_id: idFromDocumentsSelected,
        occupations_id: occupationIdSelected,
      }

      await axiosIns.post('/api/v1/sgq/document_occupation/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.idFromDocumentsSelected = []
      this.occupationIdSelected = ''
    },
  },
}
</script>
