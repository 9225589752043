<template>
  <div>
    <v-card
      class="mb-6"
    >
      <v-card-title class="pt-0 pb-0">
        <span>
          Documentos
        </span>

        <v-spacer />
        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-7 mr-4"
          label="Buscar por Identificação, Documento"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Atribuir documentos
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template
          v-slot:no-data
        >
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.edit="{item}">
          <v-icon
            medium
            class="me-2"
            color="info"
            @click="openModal('details',item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{item}">
          <v-icon
            medium
            class="me-2"
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ !item.created_at ? '' : dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showTheDocumentModal"
      width="700px"
    >
      <DocumentModal
        :occupation-list="occupationList"
        :document-list="documentList"
        @updatedTable="updatedTable()"
        @close="showTheDocumentModal = false"
      ></DocumentModal>
    </v-dialog>

    <v-dialog
      v-model="showDocumentDetails"
      width="700px"
    >
      <DocumentDetails
        :key="documentDetails.id"
        :data="documentDetails"
        :occupation-list="occupationList"
        @updatedTable="updatedTable()"
        @close="showDocumentDetails = false"
      ></DocumentDetails>
    </v-dialog>
  </div>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import DocumentDetails from './DocumentDetails.vue'
import DocumentModal from './DocumentModal.vue'

export default {
  components: {
    DocumentModal,
    DocumentDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      endpointDelete: '/api/v1/sgq/document_occupation/destroy',

      filterData: '',
      documentList: [],
      occupationList: [],
      documentDetails: [],

      headers: [
        {
          text: 'IDENTIFICAÇÃO', value: 'document.identify', sortable: false, align: 'left',
        },
        {
          text: 'DOCUMENTO', value: 'document.description', sortable: false, align: 'center',
        },
        {
          text: 'TIPO', value: 'document.type.name', sortable: false, align: 'center',
        },
        {
          text: 'FUNÇÃO', value: 'occupation.name', sortable: false, align: 'center',
        },
        {
          text: 'CRIADO EM', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'DELETAR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoading: false,
      showTheDocumentModal: false,
      showDocumentDetails: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getItemsTable()
    this.getDocumentList()
    this.getOccupationList()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns.get('api/v1/sgq/document_occupation/index').then(
        response => {
          const { data } = response.data

          this.itemsTable = data
          this.listOfFilteredItems = data
        },
      ).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
      })
    },

    async getOccupationList() {
      await axiosIns.get('api/v1/records/occupation/index').then(response => {
        const { data } = response.data

        data.forEach(({ id, occupation, sector }) => {
          this.occupationList.push({
            id,
            sector,
            occupation,
            name: `${occupation.name} - ${sector.name}`,
          })
        })
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },

    async getDocumentList() {
      await axiosIns.get('api/v1/sgq/document/index').then(
        response => {
          const { data } = response.data

          data.map(({ id, identify, description }) => {
            this.documentList.push({
              id,
              description: `${identify} - ${description}`,
            })
          })
        },
      ).catch(error => {
        this.showErrorMessage(error)
      })
    },

    findFilterResult() {
      const { filterData, itemsTable } = this
      const filter = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        ({
          document,
        }) => document.identify.toLowerCase().includes(filter)
        || document.description.toLowerCase().includes(filter),
      )
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => { this.showTheDocumentModal = true },
        details: () => { this.showDocumentDetails = true },
      }

      if (type in modal) {
        modal[type]()
        this.documentDetails = data

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>
