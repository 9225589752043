<template>
  <v-card>
    <v-card-title class="mb-2">
      <span>Detalhes</span>

      <v-spacer />

      <v-tooltip
        left
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="expand = !expand"
          >
            <v-icon
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="computedIdentify"
            label="Identificação"
            readonly
            outlined
            dense
          />

          <v-text-field
            v-model="computedDescription"
            label="Descrição"
            readonly
            outlined
            dense
          />
        </v-col>

        <v-col>
          <v-text-field
            v-model="computedOccupation"
            label="Função"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="computedDocumentType"
            label="Tipo de documento"
            outlined
            readonly
            dense
          />
        </v-col>
      </v-row>

      <v-divider />
      <br>

      <v-expand-transition>
        <v-card
          v-show="expand"
        >
          <v-autocomplete
            v-model="occupationIdSelected"
            :items="occupationList"
            item-text="name"
            item-value="occupation.id"
            label="Alterar Função"
            clearable
            outlined
            chips
            dense
          />
        </v-card>
      </v-expand-transition>

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-4"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Array, Object],
      required: true,
    },

    occupationList: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      idFromDocumentsSelected: [],
      occupationIdSelected: '',

      isLoading: false,
      expand: false,

      icons: {
        mdiPencil,
      },
    }
  },

  computed: {
    computedDocumentType() {
      return this.data.document?.type?.name ?? 'Não atribuído'
    },

    computedOccupation() {
      return this.data.occupation?.name ?? 'Não atribuído'
    },

    computedIdentify() {
      return this.data.document?.identify ?? 'Não atribuído'
    },

    computedDescription() {
      return this.data.document?.description ?? 'Não atribuído'
    },
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const { data, occupationIdSelected } = this

      const body = {
        documents_id: Array(data.documents_id),
        occupations_id: occupationIdSelected || data.occupation.id,
      }

      await axiosIns.put(`/api/v1/sgq/document_occupation/update/${data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.occupationIdSelected = ''
    },
  },
}
</script>
